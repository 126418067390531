<template>
  <b-modal
    v-model="value"
    title="Add/Edit Sponsor"
    ref="speaker-modal"
    id="speaker-modal"
  >
    <b-form-group label="Name">
      <b-form-input
        name="Name"
        v-validate="'required|min:3|max:50'"
        v-model="sponsor.name"
      ></b-form-input>
      <div v-if="submitted && errors.has('name')" class="alert-danger">
        {{ errors.first("name") }}
      </div>
    </b-form-group>
    <b-form-group label="Link">
      <b-form-input
        name="Link"
        v-validate="'required|min:3|max:50'"
        v-model="sponsor.link"
      ></b-form-input>
      <div v-if="submitted && errors.has('link')" class="alert-danger">
        {{ errors.first("link") }}
      </div>
    </b-form-group>
    <b-form-group label-size="lg" :label="$t('image')">
      <ImageUpload :url="sponsor.image" ref="image" :title="$t('image')" @updateImage="updateImage"/>
    </b-form-group>
    <template #modal-footer>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <b-button type="submit" @click="save()">Save</b-button>
      <b-button @click="value = false">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
import axios from "@/plugins/axios.plugin";
import ImageUpload from "@/components/admin/ImageUpload.vue"
export default {
  props: ["value", "sponsor", "index"],
  components: {
    ImageUpload
  },
  data() {
    return {
      message: "",
      submitted: false,
      successful: false,
      isLoading: false,
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  computed: {
    sponsors() {
      return this.$store.state.Sponsors.all;
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      this.$refs.image.uploadImage();
      if (this.index !== null) {
        this.sponsors[this.index] = this.sponsor;
        this.value = false;
      } else {
        this.sponsors.push(this.sponsor);
      }
      this.isLoading = false;
    },
    updateImage(newUrl) {
      this.sponsor.image = newUrl
      this.sponsors[this.index].image = newUrl
    }
  },
};
</script>
